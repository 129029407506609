import React, { useEffect, useState } from "react";
import { Modal, Carousel } from "antd";
import { useWindowSize } from "../../Utilities/WindowSize";
import { Product } from "../../models/oli";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
//import AutoZone from "../../../assets/Images/autozone.png";
import LeftArrowIcon2 from "../../../assets/SvgIcons/LeftArrowIcon2";
import { ProductRecommendationItemContainer } from "../ProductRecommendation/ProductionRecommendationItemContainer";

interface IProps {
  showOli: boolean;
  products: Product[];
  onCancel: Function;
}

export const ProductRecommendationModal: React.FC<IProps> = ({
  showOli,
  products,
  onCancel,
}: IProps) => {
  const windowSize = useWindowSize();
  const [sasToken, setSasToken] = useState<string>("");

  /** Functions */
  const getSasTokenAzure = async () => {
    const token = await CatalogAPI.fetchSASToken();
    //@ts-ignore
    setSasToken(token.data! || "");
  };

  useEffect(() => {
    if (windowSize.width < 576) {
      document.body.style.transform = "scale(1)";
      //window.addEventListener("touchstart", touchHandler, false);
    }
    return () => {
      if (windowSize.width < 576) {
        document.body.style.transform = "";
        //window.removeEventListener("touchstart", touchHandler, false);
      }
    };
  }, []);

  useEffect(() => {
    showOli && getSasTokenAzure();
  }, [showOli]);

  function displayRecommendedItems(products: Array<Product>) {
    const maxNumberProductsShown = 2;
    const result = [];
    for(let index = 0; index<products?.length; index=index+maxNumberProductsShown) {
      result.push(
          <ProductRecommendationItemContainer
              key={`product-rec-item-container-${index}`}
              itemList={products.slice(index, index+maxNumberProductsShown)}
              onCancel={onCancel}
              sasToken={sasToken}
          />
      )
    }
    return result;
  }

  return (
    <Modal
      title={
      <div className="product-rec-modal-title-container">
        <p className="product-rec-modal-title blue">
          Te recomendamos...
        </p>
      </div>
      }
      open={showOli}
      footer={null}
      style={{
        borderRadius: "10px",
      }}
      onCancel={() => onCancel()}
      width={windowSize.width >= 767 ? "650px" : "95%"}
      zIndex={5000}
      wrapClassName={windowSize.width < 576 ? "no-scroll wheader product-rec-modal" : "product-rec-modal"}
      bodyStyle={{
        padding: "5px 25px 35px",
        backgroundColor: "#fff",
        borderRadius: "10px",
        color: "#fff"
      }}
      closable={true}
    >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div className="product-rec-list">
        <Carousel
          className="product-rec-carousel"
          infinity={false}
          arrows={true}
          dots={false}
          nextArrow={<LeftArrowIcon2 />} // Flipped in css .slick-next
          prevArrow={<LeftArrowIcon2 />}
        >
          {
            displayRecommendedItems(products)
          }
          </Carousel>
      </div>
    </div>
    </Modal>
  );
};
