import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import RecCheckmarkIcon from "../../../assets/SvgIcons/RecCheckmarkIcon";
import { CkButton, CkIcon } from "../../../CkUI";

interface IProps {
  onCancel: Function;
  photoUrl: string;
  sasToken: string;
  viscosity: string;
  oilType: string;
  productName: string;
  mainBenefit: string;
  productDesc: string;
  productRecommendationLinks: ProductLink[];
}

interface ProductLink {
  icon: string;
  url: string;
}
export const ProductRecommendationItem: React.FC<IProps> = ({
  photoUrl,
  sasToken,
  productName,
  viscosity,
  oilType,
  mainBenefit,
  productDesc,
  productRecommendationLinks,
  onCancel,
}) => {
  const [autozoneLink, setAutozoneLink] = useState<string | null>(null);
  useEffect(() => {
    if (productRecommendationLinks && productRecommendationLinks?.length > 0) {
      const autoLink = productRecommendationLinks.find((item) => {
        return item.icon === "AUTOZONE";
      });
      if (autoLink) {
        setAutozoneLink(autoLink.url);
      } else {
        setAutozoneLink(null);
      }
    } else {
      setAutozoneLink(null);
    }
  }, [productRecommendationLinks]);

  return (
    <Row>
      <Col md={8} xs={24} className="mobile-center">
        <img
          className="rec-modal-product-img"
          src={`${photoUrl}?${sasToken}`}
          alt="OilImage"
        />
      </Col>
      <Col md={16} xs={24}>
        <Row className="mobile-center">
          <h5 className="rec-modal-product-name">
            {productName + " " + viscosity}
          </h5>
        </Row>
        <Row className="mobile-center">
          <p className="product-rec-modal-sub">{oilType}</p>
        </Row>
        <Row align="middle" className="product-rec-modal-benefit mobile-center">
          <CkIcon name="certified" />
          <p>{mainBenefit}</p>
        </Row>
        <Row>
          <p className="product-rec-modal-desc">{productDesc}</p>
        </Row>
        <Row className="rec-product-btn-container">
          <Col md={10} xs={24} className="mobile-center">
            <CkButton
              block={false}
              variant="link"
              target="_blank"
              onClick={() => {
                window.open(
                  `https://www.google.com/search?hl=es&q=${
                    productName + " " + viscosity
                  }&tbm=shop`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              Ver más proveedores
            </CkButton>
          </Col>
          <Col md={14} xs={24} className="mobile-center autozone-btn-container">
            <CkButton
              className="product-rec-button"
              disabled={!autozoneLink}
              onClick={() => {
                window.open(
                  autozoneLink || "",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              Pídelo en AutoZone
            </CkButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
