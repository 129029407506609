import { Modal, Row, Button, Checkbox, Col, Radio, Drawer, Carousel } from "antd";
import React, { ReactNode, useRef, useState, useEffect } from "react";
import {
  EnrollmentQuiz,
  EnrollmentOwnerQuizChecklist,
  EnrollmentQuizQuestions,
} from "shared/src/data-models/Workshop";
import { useSelector, useDispatch } from "react-redux";
import {
  getError,
  getLoading,
  getQuizChecklist,
  getQuizSections,
  getWorkshopSelected,
  getWorkshops,
} from "../../../../stores/selectors";
import { fetchQuizChecklist, fetchQuizSections, submitQuiz } from "shared";
import { SendOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useWindowSize } from "../../../Utilities/WindowSize";

import FocusQuality from "../../../../assets/Images/enfoque_calidad.png";
import InclusionImg from "../../../../assets/Images/inclusion_image.png";
import FairPrice from "../../../../assets/Images/precio_justo.png";
import RespectForCommitments from "../../../../assets/Images/respeto_a_compromisos.png";
import TransparentWithTheCustomer from "../../../../assets/Images/transparente_con_cliente.png";

import { CarouselRef } from "antd/lib/carousel";
import { CKCloseIcon } from "../../../../assets/SvgIcons";
import { CkButton } from "../../../../CkUI";

import "./styles.css";
import { gtmSetId } from "../../../Utilities";
interface IProps {
  visible: boolean;
  setVisible: Function;
  workshopId: string;
  contentWrapper?: "modal" | "drawer" | "section";
  showTitle?: boolean;
  prevStep?: Function;
  prevStepText?: string;
  nextStep?: Function;
  onStepChange?: (current: number, goTop: boolean) => void;
}

interface quizSection {
  sectionName: string;
  content: ReactNode;
}

/* let answersDic: {
  [sectionCode: string]: EnrollmentQuizQuestions[];
} = {}; */

export const QuizModal: React.FC<IProps> = ({
  visible,
  setVisible,
  workshopId,
  contentWrapper = "drawer",
  prevStep,
  prevStepText,
  nextStep,
  showTitle = true,
  onStepChange
}) => {
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const quizCarouselRef = useRef<CarouselRef>(null);

  const [answersDic, setAnswersDic] = useState<{
    [sectionCode: string]: EnrollmentQuizQuestions[];
  }>({});
  const currentStepRef = useRef<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const sections = useSelector(getQuizSections);
  const checklist = useSelector(getQuizChecklist);
  const workshopSelected = useSelector(getWorkshopSelected);
  const workshops = useSelector(getWorkshops);

  const [error, action] = useSelector(getError);

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [quizPages, setQuizPages] = useState<quizSection[]>([]);
  const [viewConfirmationModal, setViewConfirmationModal] =
    useState<boolean>(false);

  const loading: boolean = useSelector(getLoading);

  const [checkboxList, setCheckboxList] = useState<
    EnrollmentOwnerQuizChecklist[]
  >([]);

  const windowSize = useWindowSize();

  const quizRef = useRef<HTMLDivElement>(null);

  const scrollToRef = (ref: any) => {
    ref.current && ref.current.scrollIntoView({
      behavior: "smooth"
    });
  };

  useEffect(() => {
    updateCarouselHeight(-1,false);
  }, [windowSize.width]);

  const updateCarouselHeight = (current: any, goTop: boolean = true) => {
    if (quizCarouselRef.current === null) return null;
    const container = quizCarouselRef.current.innerSlider.list.querySelectorAll(".slick-slide")[current] || quizCarouselRef.current.innerSlider.list.querySelectorAll(".slick-current")[0];
    if (container === undefined) return null; 
    const childrens = [...(container!.childNodes! || [])];
    const clientHeight = childrens.reduce((prev, current) => {
      return prev + current.clientHeight;
    }, 20);
    quizCarouselRef.current!.innerSlider.list.style.height = `${clientHeight}px`; 
    onStepChange && onStepChange(-1, goTop);
  };


  React.useEffect(() => {
    dispatch(fetchQuizSections());
    dispatch(fetchQuizChecklist());
  }, []);

  React.useEffect(() => {
    if (contentWrapper === "section") return;
    if (!error && action === "submit") {
      setViewConfirmationModal(true);
    } else {
      setViewConfirmationModal(false);
    }
  }, [error, action]);
  React.useEffect(() => {
    if (currentStep === currentStepRef.current) return;
    if (currentStep > 0) {
      canContinue(sections[currentStep - 1].sectionCode);
    } else {
      setDisableNext(false);
    }
    currentStepRef.current = currentStep;
  }, [currentStep]);

  React.useEffect(() => {
    if (sections.length > 0 && checklist != null) {
      let pages: quizSection[] = [];
      const checklistContent = (
        <div>
          <h6>
            {/* Please tell us what features your workshop has. */}
            Mi taller cuenta con:
          </h6>
          <Checkbox.Group
            onChange={(e) => {
              updateCheckboxList(e as string[]);
            }}
            defaultValue={
              checkboxList.map((option) => {
                if (option.IsSelected) {
                  return option.QuizChecklistCode;
                }
              }) as any[]
            }
            disabled={submitted}
          >
            <Row>
              {checklist.options?.map((option, index) => (
                <Col lg={12} xs={24} key={index}>
                  <Checkbox style={{ margin: "0px 0px 30px 0px" }} className="--alter --orange" value={option.code} id={option?.htmlId} onClick={(ev) => gtmSetId(ev.currentTarget)}>
                    {option.desc}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </div>
      );

      pages = [
        { sectionName: checklist.sectionName, content: checklistContent },
      ];
      sections.forEach((section) => {
        const sect = (
          <div style={{ marginBottom: 16 }}>
            <h6 className="blue">
              {/* You are almost there. */}
              {currentStep === 1 && "Queremos conocerte"}
              {currentStep === 2 && "Otros detalles"}
              {currentStep === 3 && "Para finalizar"}
              {currentStep > 3 &&
                "En CarKer construimos una comunidad de talleres que se destacan por:"}
            </h6>
            <p className="hint">
              {/* Please give a little bit more details about you and your workshop
              by answering the following questions. */}
              {currentStep < quizPages?.length - 1
                ? "Por favor, danos un poco más de detalles acerca de tí y tu taller respondiendo las siguientes preguntas."
                : ""}
            </p>

            <div ref={quizRef}>
              {section.questions.map((question, index) =>
                currentStep < quizPages?.length - 1 ? (
                  <div className="question-container" key={index}>
                    <p>
                      {index + 1}. {question.desc}
                    </p>
                    <Radio.Group
                      onChange={(e) =>
                        setAnswer(
                          section.sectionCode,
                          question.code,
                          e.target.value
                        )
                      }
                      defaultValue={
                        answersDic[section.sectionCode] !== undefined &&
                        answersDic[section.sectionCode].filter(item => item.quizQuestionCode === question.code)[0] &&
                        answersDic[section.sectionCode].filter(item => item.quizQuestionCode === question.code)[0].quizAnswerCode//  === answer.code
                      }
                      disabled={submitted}
                    >
                      {question.answers.map((answer, questionIndex) => (
                        <Row gutter={[0, 33]} key={questionIndex}>
                          <Col span={24} style={{ marginTop: "3px" }}>
                            <Radio
                              style={{ margin: "10px 0px 10px 0px" }}
                              className="--custom"
                              value={answer.code}
                              checked={
                                answersDic[section.sectionCode] !== undefined &&
                                answersDic[section.sectionCode].filter(item => item.quizQuestionCode === question.code)[0] &&
                                answersDic[section.sectionCode].filter(item => item.quizQuestionCode === question.code)[0].quizAnswerCode === answer.code
                              }
                              defaultChecked={
                                answersDic[section.sectionCode] !== undefined &&
                                answersDic[section.sectionCode].filter(item => item.quizQuestionCode === question.code)[0] &&
                                answersDic[section.sectionCode].filter(item => item.quizQuestionCode === question.code)[0].quizAnswerCode === answer.code
                              }
                              id={answer?.htmlId}
                              onClick={(ev) => gtmSetId(ev.currentTarget)}
                            >
                              {answer.desc}
                            </Radio>
                          </Col>
                        </Row>
                      ))}
                    </Radio.Group>
                  </div>
                ) : (
                  <div
                    key={"div" + index}
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "65%", marginTop: "3px" }}>
                        {ControlCode(question)}
                        {/* {index + 1} */}
                        {DescriptionByCode(question)}
                        {ListByCode(question)}
                        <Radio.Group
                          onChange={(e) =>
                            setAnswer(
                              section.sectionCode,
                              question.code,
                              e.target.value
                            )
                          }
                          disabled={submitted}
                        >
                          {question.answers.map((answer, Qindex) => (
                            <Row gutter={[0, 33]} key={Qindex}>
                              <Col span={24} style={{ marginTop: "3px" }}>
                                <Radio className="--custom" value={answer.code} id={answer?.htmlId} onClick={(ev) => gtmSetId(ev.currentTarget)}>
                                  <p
                                    className="standar black"
                                    style={{ display: "contents" }}
                                  >
                                    {answer.desc}
                                  </p>
                                </Radio>
                              </Col>
                            </Row>
                          ))}
                        </Radio.Group>
                      </div>
                      <div style={{ width: "35%" }}>
                        {ImageByCode(question)}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        );
        pages.push({ sectionName: section.sectionName, content: sect });
      });
      setQuizPages(pages);
    }
  }, [sections, checklist, currentStep, answersDic]);

  function ControlCode(info: any) {
    if (info.code === "TRANSPARENTWITHTHECUSTOMER") {
      return <h5 className="blue">SEA TRANSPARENTE CON EL CLIENTE</h5>;
    } else if (info.code === "FOCUSONQUALITY") {
      return <h5 className="blue">ENFOQUE EN LA CALIDAD</h5>;
    } else if (info.code === "RESPECTFORCOMMITMENTS") {
      return <h5 className="blue">RESPETO A COMPROMISOS</h5>;
    } else if (info.code === "FAIRPRICE") {
      return <h5 className="blue">PRECIO JUSTO</h5>;
    } else if (info.code === "INCLUSION") {
      // return null
      return <h5 className="blue">INCLUSION</h5>;
    } else if (info.code === "WORKSHOPRESERVEDBYRIGHTS") {
      // return null
      return (
        <p style={{ fontSize: "20px", color: "#2f66f2" }}>
          El taller CarKer se reserva los derechos de admisión en base a las
          condiciones del vehículo presentado o por el historial del cliente.
        </p>
      );
    } else {
      return null;
    }
  }

  function ListByCode(dataString: any) {
    let data = dataString.desc;
    let items: Array<String> = data.split(";");
    let inclusionInfo =
      "Los talleres CarKer se comprometen a tratar con respeto a todos los clientes independientemente de su genero, edad, sexo, preferencia, condición social, religión, etc.";
    let workshopPreservedByRights =
      "El taller CarKer se reserva los derechos de admisión en base a las condiciones del vehículo presentado o por el historial del cliente.";
    return (
      <ul className="standar black">
        {items.map((info, index) =>
          info === inclusionInfo || info === workshopPreservedByRights ? null : (
            <li key={index}>{info}</li>
          )
        )}
      </ul>
    );
  }

  function ImageByCode(info: any) {
    if (info.code === "TRANSPARENTWITHTHECUSTOMER") {
      return (
        <img
          style={{
            width: windowSize.width > 766 ? "40%" : "40%",
            objectFit: "cover",
            borderRadius: 5,
            padding: 5,
          }}
          src={TransparentWithTheCustomer}
          alt="TransparentWithTheCustomer"
        />
      );
    } else if (info.code === "FOCUSONQUALITY") {
      return (
        <img
          style={{
            width: windowSize.width > 766 ? "40%" : "40%",
            objectFit: "cover",
            borderRadius: 5,
            padding: 5,
          }}
          src={FocusQuality}
          alt="FocusQuality"
        />
      );
    } else if (info.code === "RESPECTFORCOMMITMENTS") {
      return (
        <img
          style={{
            width: windowSize.width > 766 ? "40%" : "40%",
            objectFit: "cover",
            borderRadius: 5,
            padding: 5,
          }}
          src={RespectForCommitments}
          alt="RespectForCommitments"
        />
      );
    } else if (info.code === "FAIRPRICE") {
      return (
        <img
          style={{
            width: windowSize.width > 766 ? "40%" : "40%",
            objectFit: "cover",
            borderRadius: 5,
            padding: 5,
          }}
          src={FairPrice}
          alt="FairPrice"
        />
      );
    } else if (info.code === "INCLUSION") {
      // return null
      return (
        <img
          style={{
            width: windowSize.width > 766 ? "40%" : "40%",
            objectFit: "cover",
            borderRadius: 5,
            padding: 5,
          }}
          src={InclusionImg}
          alt="InclusionImg"
        />
      );
    } else if (info.code === "WORKSHOPRESERVEDBYRIGHTS") {
      return null;
    } else {
      return null;
    }
  }

  function DescriptionByCode(info: any) {
    if (info.code === "TRANSPARENTWITHTHECUSTOMER") {
      return (
        <p className="standar black">
          La comunidad de talleres en CarKer trabaja todos los días para ganar
          su confianza. Comparten su experiencia con usted, le brindan asesoría
          adecuada y se comprometen a:
        </p>
      );
    } else if (info.code === "FOCUSONQUALITY") {
      return (
        <p className="standar black">
          Los talleres registrados por CarKer son profesionales experimentados
          que se encargan de su automóvil. Nuestros talleres están especialmente
          comprometidos con:
        </p>
      );
    } else if (info.code === "RESPECTFORCOMMITMENTS") {
      return (
        <p className="standar black">
          Los talleres en CarKer ayudan a simplificar el mantenimiento de su
          automóvil todos los días, comprometiéndose a:
        </p>
      );
    } else if (info.code === "FAIRPRICE") {
      return (
        <p className="standar black">
          Con CarKer, puede ir al taller con tranquilidad. Pagarás el precio
          cotizado, ni un peso más. Nuestros talleres están comprometidos con:
        </p>
      );
    } else if (info.code === "INCLUSION") {
      // return null
      return (
        <p className="standar black">
          Los talleres CarKer se comprometen a tratar con respeto a todos los
          clientes independientemente de su genero, edad, sexo, preferencia,
          condición social, religión, etc.
        </p>
      );
    } else if (info.code === "WORKSHOPRESERVEDBYRIGHTS") {
      return null;
    } else {
      return null;
    }
  }

  const setAnswer = (section: string, question: string, answer: string) => {
    if (section in answersDic) {
      let answers = answersDic[section];
      const index = answers.findIndex(
        (answer) => answer.quizQuestionCode === question
      );
      if (index !== -1) {
        answers.splice(index, 1);
      }
      answers.push({
        quizAnswerCode: answer,
        quizQuestionCode: question,
        quizSectionCode: section,
      });
      answersDic[section] = answers;
    } else {
      answersDic[section] = [
        {
          quizAnswerCode: answer,
          quizQuestionCode: question,
          quizSectionCode: section,
        },
      ];
    }
    setAnswersDic(answersDic);
    canContinue(section);
  };

  const updateCheckboxList = (answers: string[]) => {
    let checkboxListTemp: EnrollmentOwnerQuizChecklist[] = [];
    checklist?.options.forEach((question) => {
      let isSelected = false;
      if (answers.find((ans) => ans === question.code) !== undefined) {
        isSelected = true;
      }
      checkboxListTemp.push({
        IsSelected: isSelected,
        QuizChecklistCode: question.code,
      });
    });
    setCheckboxList(checkboxListTemp);
  };

  const submit = async () => {
    if (submitted) {
      contentWrapper !== "section" && visible && setVisible(false)
      nextStep && nextStep();
      return;
    }
    const enrollmentQuizQuestions: EnrollmentQuizQuestions[] = [];

    for (const [key, value] of Object.entries(answersDic)) {
      enrollmentQuizQuestions.push(...value);
    }

    const answers: EnrollmentQuiz = {
      enrollmentOwnerQuizQuestions: enrollmentQuizQuestions,
      enrollmentOwnerQuizChecklist: checkboxList,
      workshopID: workshopId,
    };

    // @ts-ignore
    dispatch(submitQuiz(answers)).then((result: any) => {
      if (result.meta.requestStatus === "fulfilled") {
        // setSubmitted(true);
        nextStep && nextStep();
      }
    });
  };

  const canContinue = (sectionCode: string) => {
    const section = sections.find((sec: any) => sec.sectionCode === sectionCode);
    if (section) {
      const questionAmount = section.questions.length;
      const answerAmount = answersDic[sectionCode]?.length;
      if (section.sectionName.toUpperCase() === "VROOMLYCOMMITMENTS") {
        try {
          if (
            answersDic[sectionCode][0].quizAnswerCode.toUpperCase() ===
            "ACCTRANSPARENTWITHTHECUSTOMER"
          ) {
            setDisableNext(false);
          } else {
            setDisableNext(true);
          }
        } catch {
          setDisableNext(true);
        }
      } else {
        setDisableNext(questionAmount !== answerAmount);
      }
    }
  };

  function showCloseConfirm() {
    confirm({
      title: "¿Seguro que quiere salir de la encuesta?",
      icon: <ExclamationCircleOutlined className="blue" />,
      content: "Se perderá el avance que lleva al momento",
      okText: "Salir",
      okType: "danger",
      cancelText: "Cancelar",
      zIndex: 6050,
      onOk() {
        setVisible(false);
      },
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  const onCancel = () => {
    if (currentStep > 0) {
      quizCarouselRef.current?.prev();
      setCurrentStep(currentStep - 1);
    } else {
      prevStep && prevStep();
      contentWrapper !== "section" && setVisible(false);
    }
  }

  const nextButtonIds = [
    "HDRLOGIN022",
    "HDRLOGIN036",
    "HDRLOGIN068",
    "HDRLOGIN100",
    "HDRLOGIN104"
  ];

  const prevButtonsIds = [
    "HDRLOGIN023",
    "HDRLOGIN037",
    "HDRLOGIN069",
    "HDRLOGIN101",
    "HDRLOGIN105"
  ]

  const DrawerFooter = () => (
    <div className="quiz-footer">
      <div className="buttons-container">
        {currentStep < quizPages?.length - 1 ? (
          <CkButton
            onClick={() => {
              quizCarouselRef.current?.next();
              setCurrentStep((previousState) => previousState + 1);
            }}
            disabled={disableNext}
            id={nextButtonIds[currentStep]}
            triggerGTMOnClick
          >
            Siguiente
          </CkButton>
        ) : (
          <CkButton
            onClick={submit}
            disabled={disableNext}
            loading={loading}
            id={nextButtonIds[currentStep]}
            triggerGTMOnClick
          >
            Enviar respuestas y continuar
          </CkButton>
        )}
        {!loading && (
          <CkButton
            variant="link"
            onClick={onCancel}
            block={false}
            id={prevButtonsIds[currentStep]}
          >
            {currentStep === 0 && prevStepText !== undefined && prevStepText}
            {currentStep === 0 && prevStepText === undefined && "Cancelar"}
            {currentStep > 0 && "Anterior"}
          </CkButton>
        )}
      </div>
      {quizPages && quizPages[currentStep] && (
        <p className={disableNext ? "" : "hide"}>
          {/* 
          {quizPages[currentStep].sectionName.toUpperCase() ==
            "VROOMLYCOMMITMENTS"
            ? "Debe aceptar los compromisos del taller para enviar la solicitud de activación del taller."
            : "Por favor selecciona una respuesta para cada pregunta antes de continuar."} 
          */}
          {quizPages[currentStep].sectionName.toUpperCase() === "VROOMLYCOMMITMENTS" && "Debe aceptar los compromisos del taller para enviar la solicitud de activación del taller."}
        </p>
      )}
    </div>
  )

  const DrawerTitle = () => {
    if (showTitle) return (
      <h3>
        Encuesta de taller{" "}
        {workshops &&
          workshopSelected &&
          workshops[workshopSelected] &&
          `"${workshops[workshopSelected].name}"`}
      </h3>
    )
    return null;
  }

  const modalConfirmationClose = () => {
    if (contentWrapper === "section") return;
    setVisible(false);
    setViewConfirmationModal(false);
    window.location.reload();
  }
  
  const ModalConfirmation = () => (
    <Modal
      open={viewConfirmationModal}
      footer={null} 
      maskClosable={false}
      zIndex={6000}
      closeIcon={<CKCloseIcon onClick={modalConfirmationClose} />}
      onCancel={modalConfirmationClose}
      className="quiz-confirmation-modal"
    >
      <SendOutlined />
      <h3>{/* Answers have been submitted */}
        Respuestas enviadas
      </h3>
      <p>
        {/* Your answers are being evaluated. Please allow at least one working
          day for the results to be sent to you! */}
        Tus respuestas están siendo evaluadas. ¡Por favor, permite que pase al menos un día hábil para recibir los resultados!
        
      </p>
      <p>
        {/* In case you have any questions contact: */}
        En caso de alguna pregunta contacta: <a href="mailTo:soporte@carker.com.mx" target="_blank">soporte@carker.com.mx</a>
      </p>
    </Modal>
  )

  if (contentWrapper === "modal" || contentWrapper === "drawer") {
    return (
      <Drawer
        open={visible}
        onCancel={() => showCloseConfirm()}
        footer={<DrawerFooter />}
        closeIcon={null}
        className="quiz-drawer"
        placement="right"
        zIndex={5000}
        title={<DrawerTitle />}
      >
        <div className="display-position">
          <div className={`position ${currentStep === 0 ? "active" : ""}`} />
          <div className={`position ${currentStep === 1 ? "active" : ""}`} />
          <div className={`position ${currentStep === 2 ? "active" : ""}`} />
          <div className={`position ${currentStep === 3 ? "active" : ""}`} />
          <div className={`position ${currentStep === 4 ? "active" : ""}`} />
        </div>

        {quizPages[currentStep]?.content}

        <ModalConfirmation />
      </Drawer>
    );
  }
  return (
    <section className="quiz-container">
      <DrawerTitle />
      <div className="display-position">
        <div className={`position ${currentStep === 0 ? "active" : ""}`} />
        <div className={`position ${currentStep === 1 ? "active" : ""}`} />
        <div className={`position ${currentStep === 2 ? "active" : ""}`} />
        <div className={`position ${currentStep === 3 ? "active" : ""}`} />
        <div className={`position ${currentStep === 4 ? "active" : ""}`} />
      </div>

      <div className="carousel-container">
        <Carousel
          className="quiz-carousel"
          ref={quizCarouselRef}
          infinite={false}
          swipeToSlide={false}
          swipe={false}
          touchMove={false}
          draggable={false}
          accessibility={false}
          dots={false}
          arrows={false}
          slidesPerRow={1}
          slidesToShow={1}
          slidesToScroll={1}
          speed={1000}
          afterChange={updateCarouselHeight}
          beforeChange={(_, to) => updateCarouselHeight(to)}
        >
          {quizPages && quizPages.map(quizPage => (
            <div key={quizPage.sectionName}>
              {quizPage?.content}
            </div>
          ))}
        </Carousel>
      </div>

      <DrawerFooter />
      <ModalConfirmation />
    </section>
  );
};
