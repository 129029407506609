import React from "react";
import { ProductRecommendationItem } from "./ProductRecommendationItem";
import { Divider } from "antd";

interface IProps {
  onCancel: Function;
  sasToken: string;
  itemList: any[];
}
export const ProductRecommendationItemContainer: React.FC<IProps> = ({
  onCancel,
  sasToken,
  itemList,
}) => {
  return (
    <div>
      {itemList.map((item, index) => {
        return (
          <div key={`pri-${index}`}>
            <ProductRecommendationItem
              onCancel={onCancel}
              photoUrl={item.photoUrl}
              sasToken={sasToken}
              viscosity={item.viscosity}
              oilType={item.oilType}
              productName={item.productName}
              mainBenefit={item.mainBenefit}
              productDesc={item.productDesc}
              productRecommendationLinks={item.productRecommendationLinks}
            />
            {itemList.length > 1 && (
              <Divider
                dashed
                style={index % 2 === 0 ? {} : { display: "none" }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
